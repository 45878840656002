import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, Grid, Button, Chip, Container, useTheme } from '@mui/material';
import { LibraryBooks, School, TrendingUp } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import debounce from 'lodash/debounce';

function TextbookSection() {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);
  const sectionRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (sectionRef.current) {
      const sectionTop = sectionRef.current.offsetTop;
      const sectionHeight = sectionRef.current.offsetHeight;
      const scrollPosition = window.scrollY - sectionTop;
      const sectionProgress = scrollPosition / (sectionHeight - window.innerHeight);
      const newIndex = Math.floor(sectionProgress * contentData.length);
      setActiveIndex(Math.max(0, Math.min(newIndex, contentData.length - 1)));
    }
  }, []);

  const contentData = [
    {
      type: 'Textbooks',
      title: 'Master Math: From Fundamentals to Advanced Reasoning',
      subtitle: 'Unlock the power of mathematical thinking with our proven methods',
      description: 'Our textbooks are meticulously crafted to build a strong foundation in mathematics, focusing on logic, reasoning, and theoretical understanding. We believe in teaching not just the "how" but the "why" behind mathematical concepts.',
      features: [
        { icon: <LibraryBooks />, label: 'Comprehensive coverage' },
        { icon: <School />, label: 'Engaging examples' },
        { icon: <TrendingUp />, label: 'Skill-building exercises' }
      ],
      image: '/path/to/textbook-mockup.png'
    },
    {
      type: 'Workbooks',
      title: 'Practice Makes Perfect: Math Workbooks',
      subtitle: 'Reinforce learning with hands-on problem solving',
      description: 'Our workbooks provide ample opportunity for students to apply their knowledge, with a wide range of exercises designed to challenge and engage. From basic arithmetic to complex problem-solving, these books support active learning.',
      features: [
        { icon: <LibraryBooks />, label: 'Varied Exercise Types' },
        { icon: <School />, label: 'Progressive Difficulty' },
        { icon: <TrendingUp />, label: 'Self-assessments' }
      ],
      image: '/path/to/workbook-mockup.png'
    },
    {
      type: 'Teacher Books',
      title: 'Empower Educators: Comprehensive Teacher Guides',
      subtitle: 'Support effective math instruction with our detailed resources',
      description: 'Our teacher books offer comprehensive guidance for educators, including lesson plans, teaching strategies, and additional resources. These books help teachers create engaging and effective math lessons that cater to diverse learning styles.',
      features: [
        { icon: <LibraryBooks />, label: 'Detailed Lesson Plans' },
        { icon: <School />, label: 'Detailed Solutions' },
        { icon: <TrendingUp />, label: 'Multiple Difficulty Levels' }
      ],
      image: '/path/to/teacherbook-mockup.png'
    }
  ];
  
  useEffect(() => {
    const handleScroll = () => {
        if (sectionRef.current) {
            const sectionTop = sectionRef.current.offsetTop;
            const sectionHeight = sectionRef.current.offsetHeight;
            const scrollPosition = window.scrollY - sectionTop;
            const maxScroll = sectionHeight - window.innerHeight;
            const sectionProgress = scrollPosition / maxScroll;
        
            // Adjust this factor to change the scroll speed
            const scrollSpeedFactor = 10000; // Increase this value to make content change faster
        
            const adjustedProgress = Math.min(1, sectionProgress * scrollSpeedFactor);
            const newIndex = Math.floor(adjustedProgress * contentData.length);
            setActiveIndex(Math.max(0, Math.min(newIndex, contentData.length - 1)));
          }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const debouncedHandleScroll = useCallback(debounce(handleScroll, 10), [handleScroll]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const activeContent = contentData[activeIndex];

  return (
    <Box
      ref={sectionRef}
      sx={{
        height: `${100 * contentData.length}vh`,
        position: 'relative',
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          background: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
        }}
      >
        <Container maxWidth="lg">
          <AnimatePresence mode="wait">
            <motion.div
              key={activeContent.type}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ 
                type: "spring",
                stiffness: 200,
                damping: 20,
                duration: 0.3
              }}
            >
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Box sx={{ 
                      height: { xs: 300, md: 500 }, 
                      width: '100%',
                      maxWidth: 600,
                      mx: 'auto',
                      position: 'relative',
                      perspective: '1000px',
                      '&:hover .book-cover': {
                        transform: 'rotateY(-30deg)',
                      },
                    }}>
                      <Box
                        className="book-cover"
                        sx={{
                          width: '100%',
                          height: '100%',
                          position: 'relative',
                          transformStyle: 'preserve-3d',
                          transition: 'transform 0.5s',
                        }}
                      >
                        <img 
                          src={activeContent.image}
                          alt={`${activeContent.type} Mockup`}
                          style={{ 
                            width: '100%', 
                            height: '100%', 
                            objectFit: 'cover', 
                            borderRadius: '8px',
                            boxShadow: '0 10px 30px rgba(0,0,0,0.2)',
                          }}
                        />
                      </Box>
                    </Box>
                  </motion.div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ px: { xs: 2, md: 4 } }}>
                    <motion.div
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    >
                      <Typography variant="h2" gutterBottom sx={{ 
                        fontWeight: 700, 
                        color: theme.palette.primary.main,
                        fontSize: { xs: '2.5rem', md: '3.5rem' },
                        textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                        paddingBottom: '10px',
                        marginBottom: '20px',
                      }}>
                        {activeContent.title}
                      </Typography>
                      <Typography variant="subtitle1" paragraph sx={{ 
                        color: theme.palette.grey[500], 
                        mb: 4,
                        fontSize: { xs: '1.2rem', md: '1.3rem' },
                        fontWeight: 300,
                      }}>
                        {activeContent.subtitle}
                      </Typography>
                      <Typography variant="body1" paragraph sx={{ mb: 6, fontSize: '1.1rem', textAlign: 'justify' }}>
                        {activeContent.description}
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 4 }}>
                        {activeContent.features.map((feature, index) => (
                          <motion.div
                            key={index}
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.3, delay: 0.1 * index }}
                            whileHover={{ scale: 1.05 }}
                          >
                            <Chip
                              icon={feature.icon}
                              label={feature.label}
                              sx={{ 
                                fontWeight: 'bold',
                                bgcolor: theme.palette.background.paper,
                                '& .MuiChip-label': { color: theme.palette.text.primary },
                                '& .MuiChip-icon': { color: theme.palette.primary.main },
                                boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                                padding: '10px 10px',
                              }}
                            />
                          </motion.div>
                        ))}
                      </Box>
                      <Button 
                        variant="contained" 
                        sx={{ 
                          mt: 4, 
                          bgcolor: theme.palette.secondary.main, 
                          color: 'white', 
                          '&:hover': { bgcolor: theme.palette.secondary.dark },
                          padding: '12px 24px',
                          fontSize: '1.1rem',
                          boxShadow: '0 4px 10px rgba(0,0,0,0.15)',
                        }}
                      >
                        Buy on Amazon
                      </Button>
                    </motion.div>
                  </Box>
                </Grid>
              </Grid>
            </motion.div>
          </AnimatePresence>
        </Container>
      </Box>
    </Box>
  );
}

export default TextbookSection;