import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Fade, Grid, Paper, LinearProgress, Avatar, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';



const SectionWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  ...theme.custom.sectionPadding,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: theme.custom.contentMaxWidth,
  margin: '0 auto',
}));

const FormCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
}));

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.components.MuiButton.styleOverrides.root,
  ...theme.components.MuiButton.styleOverrides.containedSecondary,
}));

const QuoteBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  position: 'relative',
}));

const HiddenField = styled(TextField)({
  display: 'none',
});

function CTASection() {
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [progress, setProgress] = useState(100);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    grade: '',
    subject: '',
    hiddenField: '',
  });
  const formStartTime = useRef(null);
  const mouseMovements = useRef(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = Math.max(oldProgress - 1, 0);
        if (newProgress === 0) {
          setShowForm(true);
          clearInterval(timer);
        }
        return newProgress;
      });
    }, 50);

    return () => clearInterval(timer);
  }, []);

  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === 'email') {
      setIsEmailValid(isValidEmail(value));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionTime = Date.now();
    const timeDiff = submissionTime - formStartTime.current;
  
    if (timeDiff < 3000 || formData.hiddenField || mouseMovements.current < 3) {
      setErrorMessage('Potential bot submission detected. Please try again.');
      return;
    }
  
    if (!isValidEmail(formData.email)) {
      setErrorMessage('Please enter a valid email address.');
      return;
    }
  
    // Clear any previous error messages
    setErrorMessage('');
    console.log('Form submitted:', formData);
  };

  useEffect(() => {
    if (showForm) {
      formStartTime.current = Date.now();
    }
  }, [showForm]);

  useEffect(() => {
    const handleMouseMove = () => {
      mouseMovements.current += 1;
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <SectionWrapper
    sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      position: 'relative',
    }}
    >
      <ContentWrapper>
        <Container maxWidth="lg"        > 
          <Grid container spacing={18} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <Typography variant="h2" gutterBottom sx={{ fontWeight: 'bold', mb: 3, color: 'primary.main' }}>
                Transform Your Math Journey
              </Typography>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'medium', mb: 4, color: 'text.secondary' }}>
                Join the revolution in personalized math education
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ mr: 2, color: 'secondary.main' }}>
                  {/* Add icon here */}
                </Box>
                <Typography variant="body1">
                  Adaptive learning tailored to your pace
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box sx={{ mr: 2, color: 'secondary.main' }}>
                  {/* Add icon here */}
                </Box>
                <Typography variant="body1">
                  Interactive problem-solving experiences
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                <Box sx={{ mr: 2, color: 'secondary.main' }}>
                  {/* Add icon here */}
                </Box>
                <Typography variant="body1">
                  Real-time progress tracking and insights
                </Typography>
              </Box>
              <QuoteBox sx={{ backgroundColor: theme.palette.background.paper }}>
                <FormatQuoteIcon sx={{ fontSize: 40, color: theme.palette.secondary.main, position: 'absolute', top: -20, left: -20 }} />
                <Typography variant="body1" sx={{ fontStyle: 'italic', mb: 2, color: 'text.primary' }}>
                  "This project has completely changed how I approach math. It's like having a personal tutor available 24/7!"
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ mr: 2 }}>JS</Avatar>
                  <Typography variant="subtitle2" color="text.secondary">Jane Smith, 10th Grade Student</Typography>
                </Box>
              </QuoteBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormCard elevation={3}>
                {!showForm ? (
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center', mb: 3, color: 'primary.main' }}>
                      Ready to Excel in Math?
                    </Typography>
                    <Box sx={{ width: '100%', height: '200px', backgroundColor: 'grey.300', mb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Typography variant="body2" color="text.secondary">Placeholder for engaging image</Typography>
                    </Box>
                    <Typography variant="body1" paragraph sx={{ textAlign: 'center', mb: 3 }}>
                      Join our waiting list now and be among the first to experience the future of math education. Limited spots available!
                    </Typography>
                    <LinearProgress variant="determinate" value={progress} sx={{ mb: 3 }} />
                    <Typography variant="body2" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                      Hurry! Only {Math.floor(progress / 10)} spots left!
                    </Typography>
                  </Box>
                ) : (
                  <Fade in={showForm} timeout={1000}>
                    <Box component="form" onSubmit={handleSubmit}>
                      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center', color: 'primary.main' }}>
                        Join the Waiting List
                      </Typography>
                      <TextField
                        fullWidth
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        margin="normal"
                        required
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        margin="normal"
                        required
                        error={!isEmailValid}
                        helperText={!isEmailValid ? "Please enter a valid email address" : ""}
                        sx={{ mb: 2 }}
                      />
                      <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
                        <InputLabel>Student's Grade (Optional)</InputLabel>
                        <Select
                          name="grade"
                          value={formData.grade}
                          onChange={handleChange}
                          label="Student's Grade (Optional)"
                        >
                          <MenuItem value="">Select Grade</MenuItem>
                          <MenuItem value="1">Grades 1</MenuItem>
                          <MenuItem value="2">Grades 2</MenuItem>
                          <MenuItem value="3">Grades 3</MenuItem>
                          <MenuItem value="4">Grades 4</MenuItem>
                          <MenuItem value="5">Grades 5</MenuItem>
                          <MenuItem value="6">Grades 6</MenuItem>
                          <MenuItem value="7">Grades 7</MenuItem>
                          <MenuItem value="8">Grades 8</MenuItem>
                          <MenuItem value="9">Grades 9</MenuItem>
                          <MenuItem value="10">Grades 10</MenuItem>
                          <MenuItem value="11">Grades 11</MenuItem>
                          <MenuItem value="12">Grades 12</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="normal" sx={{ mb: 3 }}>
                        <InputLabel>Subject (Optional)</InputLabel>
                        <Select
                          name="subject"
                          value={formData.subject}
                          onChange={handleChange}
                          label="Subject (Optional)"
                        >
                          <MenuItem value="">Select Subject</MenuItem>
                          <MenuItem value="arithmetic">Arithmetic</MenuItem>
                          <MenuItem value="pre-algebra">Pre-Algebra</MenuItem>
                          <MenuItem value="algebra">Algebra</MenuItem>
                          <MenuItem value="algebra 1">Algebra 1</MenuItem>
                          <MenuItem value="algebra 2">Algebra 2</MenuItem>
                          <MenuItem value="geometry">Geometry</MenuItem>
                          <MenuItem value="trigonometry">Trigonometry</MenuItem>
                          <MenuItem value="pre-calculus">Pre-Calculus</MenuItem>
                          <MenuItem value="calculus">Calculus</MenuItem>
                          <MenuItem value="statistics">Statistics</MenuItem>
                        </Select>
                      </FormControl>
                      <HiddenField
                        name="hiddenField"
                        value={formData.hiddenField}
                        onChange={handleChange}
                      />
                      {errorMessage && (
                        <Typography color="error" sx={{ mt: 2, mb: 2 }}>
                          {errorMessage}
                        </Typography>
                      )}
                      <StyledButton
                        type="submit"
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                      >
                        Secure Your Spot Now
                      </StyledButton>
                    </Box>
                  </Fade>
                )}
              </FormCard>
            </Grid>
          </Grid>
        </Container>
      </ContentWrapper>
    </SectionWrapper>
  );
}

export default CTASection;