import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import ComingSoonSection from '../components/ComingSoon';

function About() {
  return (
    <Box>
      <ComingSoonSection />
    </Box>
  );
}

export default About;