import React, { lazy, Suspense } from 'react';
import { Box, Container, CircularProgress } from '@mui/material';

// Lazy load components
const LinkTreeContent = lazy(() => import('./LinkTreeContent'));

function LinkTree() {
  return (
    <Suspense fallback={
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    }>
      <LinkTreeContent />
    </Suspense>
  );
}

export default LinkTree;