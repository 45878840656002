import React from 'react';
import { Box, Typography, Link, Grid, Divider } from '@mui/material';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram, FaYoutube } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';

function Footer() {
  const theme = useTheme();

  return (
    <Box component="footer" sx={{ backgroundColor: theme.palette.grey[900], color: theme.palette.common.white, py: 8 }}>
      <Box sx={{ maxWidth: 1200, mx: 'auto', px: 4 }}>
        <Grid container spacing={4} textAlign={{ xs: 'center', md: 'left' }}>
          
          {/* Column 1: Navigation Links */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
              Navigation
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'none', p: 0 }}>
              <li>
                <Link href="/" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Home
                </Link>
              </li>
              <li>
                <Link href="/features" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Features
                </Link>
              </li>
              <li>
                <Link href="/pricing" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Pricing
                </Link>
              </li>
              <li>
                <Link href="/academy" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Academy
                </Link>
              </li>
              <li>
                <Link href="/blog" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Blog
                </Link>
              </li>
            </Box>
          </Grid>

          {/* Column 2: Legal and Miscellaneous Links */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
              Legal
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'none', p: 0 }}>
              <li>
                <Link href="/terms" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link href="/privacy" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link href="/cookies" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Cookie Policy
                </Link>
              </li>
              <li>
                <Link href="/accessibility" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  Accessibility Statement
                </Link>
              </li>
              <li>
                <Typography variant="body2" mt={2}>
                  &copy; {new Date().getFullYear()} YourCompany
                </Typography>
              </li>
            </Box>
          </Grid>

          {/* Column 3: Contact Information */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
              Contact
            </Typography>
            <Box component="ul" sx={{ listStyleType: 'none', p: 0 }}>
              <li>1234 Example St</li>
              <li>Your City, ST 12345</li>
              <li>
                Email: <Link href="mailto:info@yourcompany.com" color="inherit" underline="hover" sx={{ '&:hover': { color: theme.palette.primary.main } }}>
                  info@yourcompany.com
                </Link>
              </li>
              <li>Phone: (123) 456-7890</li>
              <li>Hours: Mon-Fri, 9am-5pm</li>
            </Box>
          </Grid>
        </Grid>

        {/* Full-Width Social Media Section */}
        <Divider sx={{ backgroundColor: theme.palette.grey[700], mt: 8, mb: 4 }} />
        <Box textAlign="center">
          <Typography variant="h6" fontWeight={theme.typography.fontWeightBold} mb={2}>
            Follow us:
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: theme.spacing(3) }}>
            <Link href="https://facebook.com" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaFacebook />
            </Link>
            <Link href="https://twitter.com" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaTwitter />
            </Link>
            <Link href="https://linkedin.com" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaLinkedin />
            </Link>
            <Link href="https://instagram.com" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaInstagram />
            </Link>
            <Link href="https://youtube.com" target="_blank" rel="noopener noreferrer" color="inherit" sx={{ fontSize: '2rem', '&:hover': { color: theme.palette.primary.main } }}>
              <FaYoutube />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
