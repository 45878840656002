import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Typography, Box } from '@mui/material';

const AnimatedCounter = ({ value, style }) => {
  return (
    <Box sx={{ display: 'inline-block', position: 'relative', height: '1em', ...style }}>
      <AnimatePresence mode="popLayout">
        {value.toString().split('').map((digit, index) => (
          <motion.span
            key={`${index}-${digit}`}
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 20, opacity: 0 }}
            transition={{ duration: 0.5 }}
            style={{ display: 'inline-block', position: 'relative' }}
          >
            {digit}
          </motion.span>
        ))}
      </AnimatePresence>
    </Box>
  );
};

export default AnimatedCounter;