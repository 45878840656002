import React from 'react';
import HeroSection from '../components/HeroSection';
import ValuePropositionSection from '../components/ValuePropositionSection';
import MissionStatement from '../components/MissionStatement';
import HistorySection from '../components/HistorySection';
import WhoItsForSection from '../components/WhoItsForSection';
import CTASection from '../components/CTASection';
import TextbookSection from '../components/TextbookSection';
function MainPage() {
  return (
    <>
      <HeroSection />
      <WhoItsForSection />
      <ValuePropositionSection />
      <MissionStatement />
      <TextbookSection />
      <HistorySection />
      <CTASection />
    </>
  );
}

export default MainPage;