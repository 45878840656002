import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Fade, Checkbox, FormGroup, FormControlLabel, LinearProgress, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { Grid } from '@mui/material';

const SectionWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
}));

const FormCard = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(6),
  width: '100%',
  maxWidth: 600,
  boxShadow: theme.shadows[10],
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputLabel-shrink': {
    background: theme.palette.background.paper,
    padding: '0 8px',
  },
}));

const StepWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  minHeight: 200,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1.5, 4),
  fontSize: '1.1rem',
}));

const steps = [
  { label: 'Select Grade', type: 'select', name: 'grade', options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'], required: true },
  { label: 'Select Subject', type: 'select', name: 'subject', options: ['Math', 'Algebra', 'Geometry', 'Calculus', 'Statistics'], required: true },
  { label: 'Your Name', type: 'text', name: 'name', required: true },
  { label: 'Your Email', type: 'email', name: 'email', required: true },
  { label: 'Your Phone', type: 'tel', name: 'phone', required: true },
  { label: 'Preferred Teaching Method', type: 'select', name: 'teachingMethod', options: ['In Person Only', 'Online Only', 'Preferred In-Person, but Online is acceptable'], required: true },
  { label: 'ZIP Code', type: 'text', name: 'zipCode', condition: (data) => data.teachingMethod !== 'Online Only', required: true, helperText: 'Enter your 5-digit ZIP code. If outside the US, enter 00000.' },
  { label: 'Preferred Contact Method', type: 'checkbox', name: 'contactMethod', options: ['Phone Call', 'SMS', 'Email'], required: true },
];

function HireTutorSection() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    setErrors({});
  }, [currentStep]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'zipCode') {
      // For ZIP code: allow only numbers and limit to 5 digits
      const zipValue = value.replace(/\D/g, '').slice(0, 5);
      setFormData({ ...formData, [name]: zipValue });
    } else if (name === 'phone') {
      // For phone: let InputMask handle the formatting
      setFormData({ ...formData, [name]: value });
    } else {
      // For all other fields: use the original behavior
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const updatedMethods = formData[name] || [];
    if (checked) {
      updatedMethods.push(value);
    } else {
      const index = updatedMethods.indexOf(value);
      if (index > -1) {
        updatedMethods.splice(index, 1);
      }
    }
    setFormData({ ...formData, [name]: updatedMethods });
  };

  const validateStep = () => {
    const currentStepData = steps[currentStep];
    let stepErrors = {};

    if (currentStepData.required) {
      if (currentStepData.type === 'checkbox') {
        if (!formData[currentStepData.name] || formData[currentStepData.name].length === 0) {
          stepErrors[currentStepData.name] = 'Please select at least one option';
        }
      } else if (!formData[currentStepData.name]) {
        stepErrors[currentStepData.name] = 'This field is required';
      }
    }

    if (currentStepData.name === 'zipCode' && formData.zipCode) {
        const zipRegex = /^[0-9]{5}$/;
        if (!zipRegex.test(formData.zipCode)) {
          stepErrors.zipCode = 'Please enter a valid 5-digit ZIP code or 00000 if outside the US';
        }
      }
  

    if (currentStepData.name === 'email' && formData.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        stepErrors.email = 'Please enter a valid email address';
      }
    }

    if (currentStepData.name === 'phone' && formData.phone) {
      const phoneRegex = /^\+1 \(\d{3}\) \d{3}-\d{4}$/;
      if (!phoneRegex.test(formData.phone)) {
        stepErrors.phone = 'Please enter a valid phone number';
      }
    }

    setErrors(stepErrors);
    return Object.keys(stepErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateStep()) {
      console.log('Form submitted:', formData);
      alert('Form submitted successfully!');
    }
  };

  const currentStepData = steps[currentStep];

  const renderStepContent = () => {
    if (!currentStepData) return null;

    switch (currentStepData.type) {
      case 'select':
        return (
          <StyledFormControl fullWidth error={!!errors[currentStepData.name]}>
            <InputLabel>{currentStepData.label}</InputLabel>
            <Select
              name={currentStepData.name}
              value={formData[currentStepData.name] || ''}
              onChange={handleChange}
            >
              {currentStepData.options.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
            {errors[currentStepData.name] && (
              <Typography color="error" variant="caption">
                {errors[currentStepData.name]}
              </Typography>
            )}
          </StyledFormControl>
        );
      case 'checkbox':
        return (
          <FormGroup>
            {currentStepData.options.map((option) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={formData[currentStepData.name]?.includes(option) || false}
                    onChange={handleCheckboxChange}
                    name={currentStepData.name}
                    value={option}
                  />
                }
                label={option}
              />
            ))}
            {errors[currentStepData.name] && (
              <Typography color="error" variant="caption">
                {errors[currentStepData.name]}
              </Typography>
            )}
          </FormGroup>
        );
      default:
        if (currentStepData.name === 'phone') {
          return (
            <InputMask
              mask="+1 (999) 999-9999"
              value={formData[currentStepData.name] || ''}
              onChange={handleChange}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  fullWidth
                  label={currentStepData.label}
                  name={currentStepData.name}
                  error={!!errors[currentStepData.name]}
                  helperText={errors[currentStepData.name] || 'Format: +1 (xxx) xxx-xxxx'}
                />
              )}
            </InputMask>
          );
        }
        return (
            <TextField
            fullWidth
            label={currentStepData.label}
            name={currentStepData.name}
            type={currentStepData.type}
            value={formData[currentStepData.name] || ''}
            onChange={handleChange}
            error={!!errors[currentStepData.name]}
            helperText={errors[currentStepData.name] || currentStepData.helperText}
          />
        );
    }
  };

  return (
    <SectionWrapper>
      <Container maxWidth="sm">
        <FormCard>
          <Typography variant="h4" gutterBottom align="center" sx={{ mb: 4 }}>
            Hire a Math Tutor
          </Typography>
          <LinearProgress variant="determinate" value={(currentStep / (steps.length - 1)) * 100} sx={{ mb: 4 }} />
          <form onSubmit={handleSubmit}>
            <Fade in={true} timeout={500}>
              <StepWrapper>
                <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
                  {currentStepData.label}
                </Typography>
                {renderStepContent()}
              </StepWrapper>
            </Fade>
            <Grid container spacing={2} sx={{ mt: 4 }}>
              <Grid item xs={6}>
                {currentStep > 0 && (
                  <StyledButton 
                    onClick={handleBack} 
                    variant="outlined" 
                    startIcon={<ArrowBack />}
                    fullWidth
                  >
                    Back
                  </StyledButton>
                )}
              </Grid>
              <Grid item xs={6}>
                {currentStep < steps.length - 1 ? (
                  <StyledButton 
                    onClick={handleNext} 
                    variant="contained" 
                    color="primary" 
                    endIcon={<ArrowForward />}
                    fullWidth
                  >
                    Next
                  </StyledButton>
                ) : (
                  <StyledButton 
                    type="submit" 
                    variant="contained" 
                    color="primary"
                    fullWidth
                  >
                    Submit
                  </StyledButton>
                )}
              </Grid>
            </Grid>
          </form>
        </FormCard>
      </Container>
    </SectionWrapper>
  );
}

export default HireTutorSection;