import React, { useState } from 'react';
import { Box, Typography, Container, Grid, Button, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import SchoolIcon from '@mui/icons-material/School';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import WorkIcon from '@mui/icons-material/Work';
import BlockIcon from '@mui/icons-material/Block';
import WarningIcon from '@mui/icons-material/Warning';
import StarIcon from '@mui/icons-material/Star';

const valuePoints = [
  {
    icon: <SchoolIcon />,
    title: "Build Math Foundations",
    description: "Develop critical thinking skills essential for navigating today's complex world.",
    image: "https://via.placeholder.com/600x400?text=Math+Foundations"
  },
  {
    icon: <EmojiObjectsIcon />,
    title: "Boost Problem-Solving",
    description: "Equip your child with skills to tackle real-world challenges confidently.",
    image: "https://via.placeholder.com/600x400?text=Problem+Solving"
  },
  {
    icon: <WorkIcon />,
    title: "Prepare for Future Careers",
    description: "Develop skills needed for high-paying STEM careers of tomorrow.",
    image: "https://via.placeholder.com/600x400?text=Future+Jobs"
  },
  {
    icon: <BlockIcon />,
    title: "Eliminate Distractions",
    description: "Focus on real learning without digital distractions, ensuring true understanding.",
    image: "https://via.placeholder.com/600x400?text=Focused+Learning"
  },
  {
    icon: <WarningIcon />,
    title: "Prevent Math Struggles",
    description: "Avoid limiting future opportunities by building strong math skills early.",
    image: "https://via.placeholder.com/600x400?text=Math+Crisis"
  },
  {
    icon: <StarIcon />,
    title: "Unlock Full Potential",
    description: "Empower your child with essential math skills for academic and future success.",
    image: "https://via.placeholder.com/600x400?text=Full+Potential"
  }
];

const StyledGridItem = styled(Box)(({ theme, isActive }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s',
  cursor: 'pointer',
  backgroundColor: isActive ? theme.palette.primary.main : theme.palette.background.paper,
  boxShadow: isActive ? theme.shadows[4] : 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[6],
  },
}));

function ValuePoint({ icon, title, isActive, onClick }) {
  const theme = useTheme();
  return (
    <StyledGridItem isActive={isActive} onClick={onClick}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Box sx={{ 
          mr: 2, 
          color: isActive ? theme.palette.common.white : theme.palette.primary.main,
          transition: 'all 0.3s', 
          transform: isActive ? 'scale(1.1)' : 'scale(1)',
          '&:hover': {
            color: theme.palette.common.white,
          },
        }}>
          {icon}
        </Box>
        <Typography 
          variant="h6" 
          sx={{ 
            fontSize: '1.25rem', 
            fontWeight: 'bold', 
            color: isActive ? theme.palette.common.white : theme.palette.text.primary,
            transition: 'color 0.3s',
            '&:hover': {
              color: theme.palette.common.white,
            },
          }}
        >
          {title}
        </Typography>
      </Box>
    </StyledGridItem>
  );
}

function ValuePropositionSectionEnhanced() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activePoint, setActivePoint] = useState(0);

  const handlePointClick = (index) => {
    setActivePoint(index);
  };

  return (
    <Box component="section" sx={{ 
      py: { xs: 12, md: 16 }, 
      px: { xs: 2, md: 6 }, 
      height: '100vh',
      bgcolor: theme.palette.background.default,
      backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
    }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h1" 
          align="center" 
          sx={{ 
            mb: 4, 
            fontWeight: 900, 
            fontSize: { xs: '2.5rem', md: '4rem' }, 
            color: theme.palette.primary.main,
            textShadow: '2px 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          Don't Let Your Child Fall Behind
        </Typography>
        <Typography 
          variant="h4" 
          align="center" 
          sx={{ 
            mb: 10, 
            color: theme.palette.text.secondary,
            fontWeight: 500,
          }}
        >
          Master Math Today - Secure their future with strong math foundations
        </Typography>

        <Grid container spacing={8} alignItems="center">
          {!isMobile && (
            <Grid item xs={12} md={5}>
              <Box
                component="img"
                src={valuePoints[activePoint].image}
                alt={valuePoints[activePoint].title}
                sx={{
                  width: '100%',
                  height: 'auto',
                  maxHeight: '500px',
                  objectFit: 'cover',
                  borderRadius: theme.shape.borderRadius,
                  boxShadow: theme.shadows[4],
                  transition: 'opacity 0.3s',
                  border: `4px solid ${theme.palette.background.paper}`,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              {valuePoints.map((point, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <ValuePoint
                    {...point}
                    isActive={activePoint === index}
                    onClick={() => handlePointClick(index)}
                  />
                </Grid>
              ))}
            </Grid>

            <Box sx={{ 
              mt: 6, 
              p: 4, 
              bgcolor: theme.palette.background.paper, 
              borderRadius: theme.shape.borderRadius, 
              boxShadow: theme.shadows[4],
              transition: 'all 0.3s',
            }}>
              <Typography 
                variant="h5" 
                gutterBottom 
                sx={{ 
                  fontSize: '1.5rem', 
                  fontWeight: 'bold', 
                  color: theme.palette.primary.dark,
                  mb: 2,
                }}
              >
                {valuePoints[activePoint].title}
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  fontSize: '1.1rem', 
                  color: theme.palette.text.primary,
                  lineHeight: 1.6,
                }}
              >
                {valuePoints[activePoint].description}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ mt: 12, textAlign: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              py: 2,
              px: 6,
              fontSize: '1.2rem',
              fontWeight: 'bold',
              transition: 'all 0.3s',
              backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
              boxShadow: theme.shadows[4],
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: theme.shadows[8],
              },
              '&:active': {
                transform: 'scale(0.98)',
              },
            }}
          >
            Act Now – Start Your Child's Math Journey!
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default ValuePropositionSectionEnhanced;