import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, Container, Grid, Paper, TextField, MenuItem, Fade, Chip, Zoom, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { CenterFocusStrong, TrendingUp, TrackChanges, School, Person } from '@mui/icons-material';
import AnimatedCounter from './AnimatedCounter';

function HeroSection() {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [grade, setGrade] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [studentCount, setStudentCount] = useState(25600);
  const [teacherCount, setTeacherCount] = useState(1600);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setTimeout(() => setIsSubmitted(false), 3000);
  };

  const grades = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const features = [
    { label: 'Focused Practice', icon: <CenterFocusStrong /> },
    { label: 'Adaptive Learning', icon: <TrendingUp /> },
    { label: 'Progress Tracking', icon: <TrackChanges /> },
    { label: 'Curriculum Aligned', icon: <School /> },
  ];

  const testimonials = [
    { name: "Sarah J.", role: "5th Grade Teacher", state: "CA", text: "MyDailyMath has transformed how I teach math. My students are more engaged than ever!" },
    { name: "Mike R.", role: "Parent", state: "NY", grade: "7th", text: "I've seen a significant improvement in my daughter's math skills. Highly recommended!" },
    { name: "Emily L.", role: "Middle School Principal", state: "TX", text: "The adaptive learning feature ensures each student is appropriately challenged. It's a game-changer!" },
  ];

  const updateTestimonial = useCallback(() => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  }, [testimonials.length]);

  const updateCounts = useCallback(() => {
    setStudentCount((prev) => prev + Math.floor(Math.random() * 3));
    setTeacherCount((prev) => prev + Math.floor(Math.random() * 2));
  }, []);

  useEffect(() => {
    const testimonialTimer = setInterval(updateTestimonial, 5000);
    const countTimer = setInterval(updateCounts, 5000);

    return () => {
      clearInterval(testimonialTimer);
      clearInterval(countTimer);
    };
  }, [updateTestimonial, updateCounts]);

  return (
    <Box
      component="section"
      sx={{
        width: '100vw',
        height: '100vh',
        background: theme.palette.background.gradient,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
        <Grid container spacing={12} alignItems="center">
          <Grid item xs={12} md={8}>
            <Box sx={{ textAlign: 'center', mb: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: '3rem', md: '7rem' },
                    fontWeight: 800,
                    color: theme.palette.grey[50],
                    mb: 4,
                  }}
                >
                  MyDailyMath
                </Typography>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '1.25rem', md: '1.75rem' },
                    fontWeight: 500,
                    color: 'rgba(255, 255, 255, 0.9)',
                    mb: 6,
                  }}
                >
                  Empowering students with adaptive, daily math practice
                </Typography>
              </motion.div>

              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 2, mb: 6 }}>
                {features.map((feature, index) => (
                  <Chip
                    key={feature.label}
                    icon={feature.icon}
                    label={feature.label}
                    sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                      color: theme.palette.grey[50],
                      '& .MuiChip-icon': { color: theme.palette.grey[50] },
                    }}
                  />
                ))}
              </Box>

              <Box sx={{ mt: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ textAlign: 'center', mr: 4 }}>
                  <Typography variant="h3" sx={{ color: theme.palette.grey[50], display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <AnimatedCounter value={studentCount} />
                    <span style={{ marginLeft: '4px' }}>+</span>
                  </Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.grey[100] }}>
                    Students
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'center', ml: 4 }}>
                  <Typography variant="h3" sx={{ color: theme.palette.grey[50], display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                    <AnimatedCounter value={teacherCount} />
                    <span style={{ marginLeft: '4px' }}>+</span>
                  </Typography>
                  <Typography variant="body2" sx={{ color: theme.palette.grey[100] }}>
                    Teachers
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mt: 8 }}>
                <AnimatePresence mode="wait">
                  <motion.div
                    key={currentTestimonial}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <Typography variant="body1" sx={{ color: theme.palette.grey[50], fontStyle: 'italic', mb: 2 }}>
                      "{testimonials[currentTestimonial].text}"
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      {testimonials[currentTestimonial].name}, {testimonials[currentTestimonial].role}, {testimonials[currentTestimonial].state}
                    </Typography>
                  </motion.div>
                </AnimatePresence>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Fade in={true} timeout={1000}>
              <Paper
                elevation={6}
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.95)',
                  borderRadius: theme.shape.borderRadius,
                  padding: theme.spacing(6),
                  boxShadow: '0 20px 40px rgba(0, 0, 0, 0.1)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <Typography variant="h3" gutterBottom sx={{ mb: 2, fontWeight: 700, color: theme.palette.primary.main }}>
                  Get Early Access
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 4, color: theme.palette.text.secondary }}>
                  Join our waiting list and be the first to experience MyDailyMath!
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Your Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    fullWidth
                    label="Your Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    fullWidth
                    select
                    label="Grade (Optional)"
                    variant="outlined"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    sx={{ mb: 4 }}
                  >
                    <MenuItem value="">
                      <em>Select a grade</em>
                    </MenuItem>
                    {grades.map((g) => (
                      <MenuItem key={g} value={g}>
                        Grade {g}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      mb: 3,
                      py: 2,
                      borderRadius: theme.shape.borderRadius,
                      fontSize: '1.2rem',
                      fontWeight: 600,
                      backgroundColor: theme.palette.primary.main,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                        transform: 'translateY(-2px)',
                        boxShadow: '0 6px 20px rgba(34, 83, 120, 0.4)',
                      },
                      transition: 'all 0.3s ease',
                    }}
                  >
                    {isSubmitted ? 'Thanks for Joining!' : 'Join Waiting List'}
                  </Button>
                </form>
                <Typography variant="body2" align="center" sx={{ color: theme.palette.text.secondary }}>
                  Limited spots available. Sign up now!
                </Typography>
              </Paper>
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default HeroSection;