import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Header from './components/Header';
import Footer from './components/Footer';
import MainPage from './pages/MainPage';
import HireTutor from './pages/HireTutor';
import Textbook from './pages/Textbooks';
import About from './pages/About';
import Roadmap from './pages/Roadmap';
import SupportProject from './pages/SupportProject';
import NotFound from './pages/NotFound';
import LinkTree from './pages/LinkTree';

function App() {
  const hostname = window.location.hostname;
  const isLinkTree = hostname.startsWith('linktree.');

  if (isLinkTree) {
    return <LinkTree />;
  }
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/hire-tutor" element={<HireTutor />} />
            <Route path="/textbooks" element={<Textbook />} />
            <Route path="/about" element={<About />} />
            <Route path="/roadmap" element={<Roadmap />} />
            <Route path="/support-project" element={<SupportProject />} />
            <Route path="*" element={<NotFound />} />
            {/* Add more routes here as needed */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;