import React from 'react';
import { Box, Container, Typography, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/system';
import SchoolIcon from '@mui/icons-material/School';
import ComputerIcon from '@mui/icons-material/Computer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const MissionSectionWrapper = styled(Box)(({ theme }) => ({
  backgroundImage: 'url(https://lawr.ru/files/01-obrazovatelnoe-pravo-jpg-1496782904-593718380dfcf.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  color: theme.palette.text.primary,
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  backdropFilter: 'blur(10px)',
  boxShadow: theme.shadows[4],
}));

function MissionStatement() {
  return (
    <MissionSectionWrapper>
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10} lg={8}>
            <ContentBox>
              <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center', color: 'primary.main' }}>
                Affordable, High-Quality Math Education for Every Student
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, textAlign: 'center', color: 'text.secondary' }}>
                At MyDailyMath, our goal is to provide every student with access to affordable, high-quality math learning resources. By combining time-tested, traditional teaching methods with modern technology, we ensure a customized learning experience that is both effective and affordable.
              </Typography>

              <List>
                <ListItem>
                  <ListItemIcon sx={{ color: 'secondary.main' }}>
                    <SchoolIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Proven Traditional Methods" 
                    secondary="We rely on a math teaching approach that has been successful for hundreds of years, helping students build strong problem-solving skills and develop a deep understanding of fundamental concepts."
                    primaryTypographyProps={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.2rem' }}
                    secondaryTypographyProps={{ color: 'text.secondary' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ color: 'secondary.main' }}>
                    <ComputerIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Modern Technology for Customization" 
                    secondary="Our platform uses technology to tailor the learning experience to each student's needs, adapting to their pace, strengths, and challenges—without compromising on quality."
                    primaryTypographyProps={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.2rem' }}
                    secondaryTypographyProps={{ color: 'text.secondary' }}
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon sx={{ color: 'secondary.main' }}>
                    <AttachMoneyIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Affordable for All" 
                    secondary="By streamlining the learning process and cutting unnecessary costs, we're able to provide top-tier educational materials at a price that's affordable for everyone, making math education accessible to all students, regardless of background."
                    primaryTypographyProps={{ color: 'primary.main', fontWeight: 'bold', fontSize: '1.2rem' }}
                    secondaryTypographyProps={{ color: 'text.secondary' }}
                  />
                </ListItem>
              </List>
            </ContentBox>
          </Grid>
        </Grid>
      </Container>
    </MissionSectionWrapper>
  );
}

export default MissionStatement;