import React, { useState } from 'react';
import { 
  Tabs, Tab, Select, MenuItem, Button, Box, Typography, 
  Snackbar, Dialog, DialogActions, DialogContent, 
  DialogContentText, DialogTitle, Tooltip, Paper 
} from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

function DonationForm() {
  const [donationType, setDonationType] = useState('oneTime');
  const [amount, setAmount] = useState('20');
  const [customAmount, setCustomAmount] = useState('');
  const [error, setError] = useState('');
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const goalAmount = 10000;
  const currentAmount = 6500;

  const handleDonationTypeChange = (event, newValue) => {
    setDonationType(newValue);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const formatCurrency = (value) => {
    // Remove non-digit characters and leading zeros
    const digits = value.replace(/\D/g, '').replace(/^0+/, '');
    // Format with commas
    return digits.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleCustomAmountChange = (event) => {
    const value = event.target.value;
    const formattedValue = formatCurrency(value);
    setCustomAmount(formattedValue);

    // Validation
    const numericValue = parseFloat(formattedValue.replace(/,/g, ''));
    if (isNaN(numericValue) || numericValue < 1) {
      setError('Minimum donation amount is $1');
    } else {
      setError('');
    }
  };

  const handleDonate = () => {
    const donationAmount = amount === 'other' ? customAmount : amount;
    if (!donationAmount || parseFloat(donationAmount) < 1) {
      setError('Please enter a valid donation amount');
      return;
    }
    setOpenConfirmDialog(true);
  };

  const confirmDonation = () => {
    setOpenConfirmDialog(false);
    // Process donation here
    console.log(`Processing ${donationType} donation of $${amount === 'other' ? customAmount : amount}`);
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 500, margin: 'auto', p: 4, borderRadius: 4, alignContent: 'center'  }}>
      <Box sx={{ mb: 4, textAlign: 'center', contentAlign: 'center' }}>
        <img 
          src="https://placeholder.com/150x150" 
          alt="Project atmosphere" 
          style={{ borderRadius: '50%', maxWidth: '150px', marginBottom: '1rem', alignContent: 'center' }}
        />
        <Typography variant="h4" component="h1" gutterBottom>
          Support Our Cause
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ fontStyle: 'italic', mb: 2 }}>
          "The best way to find yourself is to lose yourself in the service of others." - Mahatma Gandhi
        </Typography>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Help us reach our goal:</Typography>
        <Typography variant="h4" gutterBottom>${currentAmount.toLocaleString()} / ${goalAmount.toLocaleString()}</Typography>
        <Box sx={{ width: '100%', backgroundColor: '#e0e0e0', height: 10, borderRadius: 5 }}>
          <Box 
            sx={{ 
              width: `${(currentAmount / goalAmount) * 100}%`, 
              backgroundColor: 'primary.main', 
              height: '100%', 
              borderRadius: 5 
            }} 
          />
        </Box>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {Math.round((currentAmount / goalAmount) * 100)}% of our goal reached
        </Typography>
      </Box>

      <Tabs value={donationType} onChange={handleDonationTypeChange} centered sx={{ mb: 3 }}>
        <Tab label="One-Time Gift" value="oneTime" />
        <Tab label="Monthly Support" value="monthly" />
      </Tabs>

      <Select value={amount} onChange={handleAmountChange} fullWidth sx={{ mb: 3 }}>
        <MenuItem value="5">$5</MenuItem>
        <MenuItem value="20">$20</MenuItem>
        <MenuItem value="50">$50</MenuItem>
        <MenuItem value="100">$100</MenuItem>
        <MenuItem value="other">Other Amount</MenuItem>
      </Select>

      <AnimatePresence>
        {amount === 'other' && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <TextField
              fullWidth
              label="Custom Amount"
              variant="outlined"
              value={customAmount}
              onChange={handleCustomAmountChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              sx={{ mb: 3 }}
              placeholder="0.00"
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <Tooltip title={donationType === 'oneTime' ? "Make a one-time donation" : "Set up a recurring monthly donation"} arrow>
        <Button 
          variant="contained" 
          color="primary" 
          fullWidth 
          onClick={handleDonate}
          sx={{ mb: 3, py: 1.5 }}
        >
          {donationType === 'oneTime' ? 'Make Your Gift' : 'Start Monthly Support'} 
          {amount !== 'other' && ` of $${amount}`}
        </Button>
      </Tooltip>

      <Typography variant="body1" align="center" sx={{ mb: 3 }}>
        Or support us through these platforms:
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Tooltip title="Support us on Patreon" arrow>
          <Button
            variant="outlined"
            color="secondary"
            href="https://www.patreon.com/your_page"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ flex: 1, mr: 2 }}
          >
            Patreon
          </Button>
        </Tooltip>
        <Tooltip title="Buy us a coffee" arrow>
          <Button
            variant="outlined"
            color="secondary"
            href="https://www.buymeacoffee.com/your_page"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ flex: 1 }}
          >
            Buy Me a Coffee
          </Button>
        </Tooltip>
      </Box>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />

      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Your Donation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to make a {donationType === 'oneTime' ? 'one-time' : 'monthly'} donation of ${amount === 'other' ? customAmount : amount}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
          <Button onClick={confirmDonation} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}

export default DonationForm;