import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, Link, Menu, MenuItem } from '@mui/material';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useTheme } from '@mui/material/styles';

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky" sx={{ 
      backgroundColor: theme.palette.background.paper, 
      boxShadow: theme.shadows[3], 
      padding: theme.spacing(1, 0) 
    }}>
      <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left: Logo */}
        <img src="/Logo.png" alt="MyDailyMath Logo" component={Link} href="/" style={{ width: '180px', height: '40px' }} />
        

        {/* Hamburger Icon for Mobile */}
        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
          <IconButton onClick={toggleMenu} sx={{ color: theme.palette.primary.main }}>
            {isOpen ? <FaTimes /> : <FaBars />}
          </IconButton>
        </Box>

        {/* Center: Main Menu */}
        <Box component="nav" sx={{ display: { xs: isOpen ? 'block' : 'none', md: 'flex' }, gap: theme.spacing(3) }}>
          <Link href="/" variant="dark" onMouseEnter={handleMenuClick}>
            MyDailyMath
          </Link>
          <Link href="/support-project" variant="dark">
            Support the Project
          </Link>
          <Link href="/hire-tutor" variant="dark">
            Hire Tutor
          </Link>
          <Link href="/Textbooks" variant="dark">
            Textbooks
          </Link>
          <Link href="/roadmap" variant="dark">
            Our Roadmap
          </Link>
          <Link href="/about" variant="dark">
            About the Project
          </Link>
        </Box>

        {/* Right: Get Start Button */}
        <Box sx={{ display: { xs: isOpen ? 'block' : 'none', md: 'block' } }}>
          <Button 
            href="/get-start" 
            variant="contained" 
            sx={{ 
              backgroundColor: theme.palette.primary.main,
              visibility: 'hidden',
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              }
            }}
          >
            Get Started
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
