import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import DonationForm from '../components/DonationForm';

function SupportProject() {
  return (
    <Box>
      <DonationForm />
    </Box>
  );
}

export default SupportProject;