import React from 'react';
import { Box, Container, Typography, Button, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';

const HeroWrapper = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url("/path-to-your-background-image.jpg")',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: theme.palette.common.white,
}));

const ContentBox = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1.5, 4),
  fontSize: '1.1rem',
}));

const LargeTitle = styled(Typography)(({ theme }) => ({
  fontSize: '4rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
  color: theme.palette.grey[100],
  fontWeight: 'bold',
}));

function ComingSoonSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <HeroWrapper>
      <Container maxWidth="sm">
        <ContentBox>
          <LargeTitle variant="h1" component="h1" gutterBottom>
            Coming Soon
          </LargeTitle>
          <Typography variant="h5" component="h2" gutterBottom>
            We're working on something exciting!
          </Typography>
          <Typography variant="body1" paragraph>
            Our new feature is almost ready. Stay tuned for updates and be the first to know when we launch.
          </Typography>
          <StyledButton variant="contained" color="primary" size="large">
            Notify Me
          </StyledButton>
        </ContentBox>
      </Container>
    </HeroWrapper>
  );
}

export default ComingSoonSection;